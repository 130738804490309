<template>
    <v-card>
        <v-card-title>Generar Nota de Crédito</v-card-title>
        <v-card-text>
            <v-row class="ma-0 mt-4">
                <v-col cols="6" class="pb-0">
                    <v-card-subtitle class="pa-0" v-if="invoice_to_note.sale.company!=undefined">Venta {{invoice_to_note.sale_id}} | {{invoice_to_note.sale.company.attributes.razon_social}}</v-card-subtitle>
                    <v-card-subtitle class="pa-0">Factura {{invoice_to_note.uuid}}</v-card-subtitle>
                </v-col>
                <v-col cols="6" class="pb-0">
                    <v-select class="mr-3" item-text="type" item-value="id" outlined dense v-model="credit_note.related_documents[0].relationship" clearable :items="relationships" label="Tipos de Relación" ></v-select>
                </v-col>
            </v-row>
            <v-row class="ma-0 mt-4">
                <v-select class="mr-3" outlined dense v-model="credit_note.payment_form" clearable :items="payment_forms" label="Forma de Pago" ></v-select>
                <v-autocomplete class="ml-3" outlined dense v-model="credit_note.use" :items="cfdiLists" label="Uso de CFDI" item-text="Descripción" item-value="Clave">
                    <template slot="no-data">No existen usos relacionados.</template>                      
                </v-autocomplete>
            </v-row>
            <div>
                <v-row class="ma-0" style="border-bottom:1px solid grey; font-size:16px; line-height:40px;" v-for="(item, index) in credit_note.items" :key="index">
                    <v-col cols="1" class="pb-0">
                        <v-checkbox class="ma-0" v-model="item.selected"></v-checkbox>
                    </v-col>
                    <v-col cols="3" class="px-0 pb-0">
                        <v-text-field type="number" style="width:100px!important;" outlined dense label="Cantidad" v-model="item.quantity"></v-text-field>
                    </v-col>
                    <v-col cols="5" class="px-0 pb-0">
                        {{item.product.description}}
                    </v-col>
                    <v-col cols="3" class="px-0 pb-0" style="text-align:right;">
                        <strong>{{(item.product.price * item.quantity).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</strong>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-row class="ma-0 mb-4">
                <v-spacer/>
                <div style="text-align:center;">
                    <strong>Subtotal =</strong> {{(subtotal).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                    <br/>
                    <strong>IVA =</strong> {{(subtotal*.16).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                    <br/>
                    <strong>Total =</strong> {{(subtotal*1.16).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                    <br/>
                    <br/>
                    <v-btn @click="generateCreditNote()" class="elevation-0" color="primary">Generar Nota de Crédito</v-btn>
                </div>
                <v-spacer/>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from "axios"
import fiscal_catalogs from '../../mixins/fiscal_catalogs'
export default {
    mixins: [fiscal_catalogs],
    props:{
        invoice_to_note:Object
    },
    data: () => ({
        relationships:[
            {id:'01', type: 'Notas de Crédito de Documentos Relacionados'},
            {id:'02', type: 'Notas de Débito de los Documentos Relacionados'},
            {id:'03', type: 'Devolución de Mercancías sobre Facturas o Traslados Previos'},
            {id:'04', type: 'Sustitución de los CFDI Previos'},
            {id:'05', type: 'Traslados de Mercancías Facturados Previamente'},
            {id:'06', type: 'Factura Generada por los Traslados Previos'},
            {id:'07', type: 'CFDI por Aplicación de Anticipo'},
            {id:'08', type: 'Facturas Generadas por Pagos en Parcialidades'},
            {id:'09', type: 'Factura Generada por Pagos Diferidos'},
        ],
        credit_note:{
            type:'E',
            customer:undefined,
            payment_form:undefined,
            items:[
                {
                    quantity:undefined,
                    product:{
                        description:undefined,
                        price:undefined,
                        product_key: undefined,
                        tax_included: false,
                        taxability: "02",
                        taxes: [{
                            type: "IVA",
                            rate: 0.16
                        }],
                        sku: undefined,
                        unit_key: undefined,
                        unit_name: undefined,

                    }
                }
            ],
            related_documents:[{
                relationship:'',
                documents:[]
            }],
            use:undefined,
            external_id:undefined
        }
    }),
    methods:{
        close(facturapi){
            this.$emit("closeCreditNoteDialog", facturapi)
        },
        generateCreditNote(){
            const credit_note = [this.credit_note].map(note=>{return{
                ...note,
                items: note.items.filter(item=>item.selected == true).map(item=>{
                    return{
                        quantity:item.quantity,
                        product:item.product
                    }
                })
            }})[0]
            let config = {
                headers: {
                    'Authorization': "Bearer " + process.env.VUE_APP_FACTURAPI_TOKEN,
                    'Content-Type': "application/json"
                }
            }
            axios.post('https://www.facturapi.io/v2/invoices', credit_note, config).then(response => {
                const facturapi = response.data
                const sale = {
                    facturapi_id: facturapi.id,
                    organization: facturapi.organization,
                    date: new Date(facturapi.created_at).toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).toString().slice(0, 19),
                    type: facturapi.type,
                    uuid: facturapi.uuid,
                    use: facturapi.use,
                    folio_number:facturapi.folio_number,
                    payment_form: facturapi.payment_form,
                    payment_method: facturapi.payment_method,
                    cfdi_version: facturapi.cfdi_version,
                    facturapi_customer_id: facturapi.customer.id,
                    verification_url: facturapi.verification_url,
                    status: facturapi.status,
                    cancellation_status: facturapi.cancellation_status,
                    total: facturapi.total,
                    series: facturapi.series,
                    salesId: [facturapi.external_id*1],
                    sale_id: facturapi.external_id*1,
                    conditions: facturapi.conditions,
                }
                axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/invoices", sale).then(agua => {
                    let config2 = {
                        responseType: 'arraybuffer',
                        headers: {
                            'Authorization': "Bearer " + process.env.VUE_APP_FACTURAPI_TOKEN,
                            'Content-Type': 'application/json',
                            'Accept': 'application/pdf'
                        }
                    }
                    axios.get('https://www.facturapi.io/v2/invoices/' + facturapi.id + '/pdf', config2).then((resp) => {
                        const url = window.URL.createObjectURL(new Blob([resp.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', facturapi.uuid + '.pdf'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.close(facturapi)
                    }).catch(error => {
                        this.snackbar = {
                            message: 'Ya se genero la nota de crédito pero, ' + error.response.data.message,
                            color: 'error',
                            show: true
                        }
                    })
                }).catch(error => {
                    this.snackbar = {
                        message: 'Ya se genero la nota de crédito pero, ' + error.response.data.message,
                        color: 'error',
                        show: true
                    }
                })
            }).catch(error => {
                this.loading_button = false
                this.snackbar = {
                    message: error.response.data.message,
                    color: 'error',
                    show: true
                }
            })
        },
        empatar(){
            this.credit_note.customer = this.invoice_to_note.facturapi_customer_id
            this.credit_note.use = this.invoice_to_note.use
            this.credit_note.external_id = this.invoice_to_note.sale_id.toString()
            this.credit_note.related_documents[0].documents = [this.invoice_to_note.uuid]
            this.credit_note.payment_form = this.invoice_to_note.payment_form
            this.credit_note.items = this.invoice_to_note.sale.items.map(item=>{
                return{
                    quantity:item.quantity,
                    product:{
                        description:this.productName(item.item.name),
                        price:item.price,
                        product_key: item.item.sat_key_code,
                        tax_included: false,
                        taxability: "02",
                        taxes: [{
                            type: "IVA",
                            rate: 0.16
                        }],
                        sku: item.item[this.sku] + '',
                        unit_key: this.products_units.filter(unit=>unit.name == this.returnName(item.item.unit)).map(unit=>unit.key)[0],
                        unit_name: this.products_units.filter(unit=>unit.name == this.returnName(item.item.unit)).map(unit=>unit.name)[0],
                    },
                    selected:true
                }
            })
        },
        returnName(unit){
            if(unit!=undefined){
                return unit.name
            }
        },
        productName(name){
            if(process.env.VUE_APP_BACKEND_ROUTE == "https://backend.ferreteriaenlinea.com/"){
                return name
            }else if(process.env.VUE_APP_BACKEND_ROUTE == "https://suministros.uno/"){
                if(name!=undefined&&name.toLowerCase().includes('trapo')){
                    return 'Trapo Industrial'// Multicolor'
                }else{
                    return name
                }
            }
        }
    },
    computed:{
        subtotal(){
            var sum = 0
            for(var i=0; i<this.credit_note.items.length; i++){
                if(this.credit_note.items[i].selected){
                    sum = sum + (this.credit_note.items[i].quantity*this.credit_note.items[i].product.price)
                }
            }
            return sum
        },
        cfdiLists(){
            return this.$store.state.cfdi.uses;
        },
        sku(){
            if(process.env.VUE_APP_BACKEND_ROUTE == "https://suministros.uno/"){
                return 'id'
            }else if(process.env.VUE_APP_BACKEND_ROUTE == "https://backend.ferreteriaenlinea.com/"){
                return 'code_one'
            }
        },
    },
    created(){
        this.empatar()
    },
    watch:{
        invoice_to_note:{
            handler(){
                this.empatar()
            }, deep: true
        }
    }
}
</script>

<style>

</style>